import React, { useState, useEffect } from 'react';
// import { userIsAuthenticated } from 'helpers/requests/users';
// import { handleUnexpectedStatusCode } from 'helpers/errors';
import AuthContext from 'contexts/AuthContext';

const AuthContextProvider = ({ children }) => {
  let [loading, setLoading] = useState(true);
  let [isAuthenticated, setIsAuthenticated] = useState(false);

  let contextData = {
    isAuthenticated: isAuthenticated,
    setIsAuthenticated: setIsAuthenticated
  };

  const handleUserIsAuthenticated = async () => {
    // const r = await userIsAuthenticated();
    // if (r.success) {
    //   setIsAuthenticated(r.response.data.is_authenticated);
    // } else {
    //   handleUnexpectedStatusCode(r.expectedStatusCode, r.response.status);
    // }
    setLoading(false);
  };

  useEffect(() => {
    handleUserIsAuthenticated();
  }, []);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
